<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
        <div class="d-flex justify-space-between">
          <h1 style="font-size: 20px; color: #000000 !important;margin-left: -10px;margin-bottom: 8px;">
            {{ $t('webPageManage.experience.OnlineConsultation') }}
          </h1>
          <h2 style="font-size: 17px; font-weight: normal; color: #616161">
            {{ $t('webPageManage.experience.ReceivedDate') }}:
            <span>
              {{ data.createdAt && formatDate(data.createdAt) }}
            </span>
          </h2>
        </div>
        <div class="d-inline-flex" style="height: 50px">
          <p class="mt-2" style="font-size: 16px; color: #666666">
            {{ $t('webPageManage.experience.status') }}
          </p>
          <v-col style="padding: 0;padding-left: 8px;">
            <v-select
              style="width: 120px; color: #666666 !important;"
              v-model="status"
              dense
              :items="[
                { id: 'Handled', name: '対応済' },
                { id: 'New', name: '未対応' },
              ]"
              item-text="name"
              item-value="id"
              outlined
            ></v-select>
          </v-col>
        </div>

        <div class="d-flex align-center">
          <div
            v-if="!data.clientId"
            class="d-flex justify-space-between div-alert mr-2"
          >
            <div>
              <i
                class="fas fa-exclamation-triangle mt-2"
                style="font-size: 22px; color: white"
              ></i
              ><span class="mt-2 ml-3 mr-2" style="font-size: 16px">{{
                $t('webPageManage.experience.NoCustomers')
              }}</span>
            </div>
            <div>
              <v-btn
                class="mr-2 btn-div-alert"
                @click="visibleCompChirld = true"
              >
                {{
                  $t('webPageManage.experience.ConnectWithExistingCustomers')
                }}
              </v-btn>
              <v-btn class="btn-div-alert" @click="addNewClientID(data.id)">
                {{ $t('webPageManage.experience.CreateANewCustomer') }}
              </v-btn>
            </div>
          </div>
          <div v-else>
            <v-btn
              :to="
                checkPerUser
                  ? '/customer-details/' +
                    data.clientId +
                    '?typeClient=' +
                    data.client.type
                  : ''
              "
              target="_blank"
              class="btn-customer-details"
              >顧客詳細</v-btn
            >
          </div>
        </div>

        <v-row class="mt-1">
          <v-col cols="6">
            <v-card class="pa-5">
              <div class="d-flex justify-space-between">
                <h4 style="font-size: 20px;color: #000000 !important;">
                  {{ $t('webPageManage.experience.CustomerInformation') }}
                </h4>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>{{ $t('webPageManage.experience.Name') }}</span>
                  <span class="mr-5">{{ data.name }}</span>
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>{{ $t('webPageManage.experience.Furigana') }}</span>
                  <span class="mr-5"
                    >{{ data.lastName }}&emsp;{{ data.firstName }}</span
                  >
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>{{ $t('webPageManage.experience.Birthday') }}</span>
                  <span class="mr-5">{{ data.birthday }}</span>
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>{{ $t('webPageManage.experience.PostalCode') }}</span>
                  <span class="mr-5">{{ data.postalCode }}</span>
                </div>
              </div>
              <div
                class="d-flex justify-space-between"
                style="border-bottom: 1px solid #bbbbbb"
              >
                <div class="boder_padding mr-3" style="margin: auto ; width: 15%;">
                  {{ $t('webPageManage.experience.Address') }}
                </div>
                <div style="width: 560px">
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.Prefectures') }})</span
                    >
                    <span class="mr-5">{{ data.prefecture }}</span>
                  </div>
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.CityOrCounty') }})</span
                    >
                    <span class="mr-5">{{ data.locality }}</span>
                  </div>
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.TownName') }}・{{
                        $t('webPageManage.experience.address')
                      }})</span
                    >
                    <span class="mr-5">{{ data.address1 }}</span>
                  </div>
                  <div class="boder_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.BuildingName') }}・{{
                        $t('webPageManage.experience.CondominiumName')
                      }})</span
                    >
                    <span class="mr-5">{{ data.address2 }}</span>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-space-between"
                style="border-bottom: 1px solid #bbbbbb"
              >
                <div class="boder_padding mr-3" style="margin: auto;width: 15%;">
                  {{ $t('webPageManage.experience.ContactInformation') }}
                </div>
                <div style="width: 560px">
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span>{{
                      $t('webPageManage.experience.HomePhoneNumber')
                    }}</span>
                    <span class="mr-5">{{ data.tel }}</span>
                  </div>
                  <div class="boder_padding d-flex justify-space-between">
                    <span>{{
                      $t('webPageManage.experience.cellPhoneNumber')
                    }}</span>
                    <span class="mr-5">{{ data.telMobile }}</span>
                  </div>
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span
                    >メールアドレス</span
                  >
                  <span class="mr-5">{{ data.email }}</span>
                </div>
              </div>
              <div class="mt-5 mx-4">
                <span style="font-size: 12px;color: #000000" class="blod">{{
                  $t('webPageManage.experience.remarks')
                }}</span>
                <v-textarea
                  v-model="remarks"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="28"
                  :rules="[$rules.checkTextAreaLength()]"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <div class="d-flex flex-column h-100" style="height: 90%;">
              <div class="h-100">
                <v-card class="pa-5">
                  <h1 style="font-weight: normal; font-size: 23px;color: #333333 !important">
                    {{
                      $t(
                        'webPageManage.experience.OnlineConsultationDesiredDate',
                      )
                    }}・{{
                      $t('webPageManage.experience.ContentOfConsultation')
                    }}
                  </h1>
                  <v-divider class="mt-5"></v-divider>
                  <div>
                    <div
                      class="boder_gray_padding d-flex justify-space-between"
                      style="font-size: 14px !important;color: #757575"
                    >
                      <span style="color: #757575">
                        {{ $t('webPageManage.experience.FirstChoiceDate') }}
                      </span>
                      <span
                        class="d-flex w-50 justify-end font-weight-bold"
                        style="color: #212121;font-size: 14px"
                      >
                        <span class="w-50">
                          {{
                            data.firstDate
                              ? data.firstDate.replace(/-/g, '/').substr(5, 5)
                              : ''
                          }}
                        </span>
                        <span class="w-50">
                          {{
                            data.firstTime ? data.firstTime.substr(0, 5) : ''
                          }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      class="boder_gray_padding d-flex justify-space-between"
                      style="font-size: 14px !important;"
                    >
                      <span style="color: #757575">
                        {{ $t('webPageManage.experience.secondChoiceDate') }}
                      </span>
                      <span
                        class="d-flex w-50 justify-end font-weight-bold"
                        style="color: #212121"
                      >
                        <span class="w-50">
                          {{
                            data.secondDate
                              ? data.secondDate.replace(/-/g, '/').substr(5, 5)
                              : ''
                          }}
                        </span>
                        <span class="w-50">
                          {{
                            data.secondTime ? data.secondTime.substr(0, 5) : ''
                          }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <web-request-detail-inquiry :item="data" />
                  </div>
                </v-card>
              </div>
            </div>
            <v-card class="d-flex justify-end mt-6" flat>
                <v-btn
                  large
                  class="t-btn--red-dark"
                  @click="closeDialog"
                  style="font-size: 14px !important"
                >
                  <v-icon class="mr-2">mdi-close</v-icon>
                  {{ $t('webPageManage.experience.close') }}
                </v-btn>
                <v-btn
                  large
                  class="t-btn--prm ml-2 btn-save"
                  style="font-size: 14px !important"
                  @click="save(data.id)"
                >
                  {{ $t('webPageManage.experience.save') }}
                </v-btn>
              </v-card>
          </v-col>
        </v-row>
        <connect-existing-customer
          :visibleCompChirld="visibleCompChirld === true"
          :id="data.id"
          @close="visibleCompChirld = false"
          :type="1"
        />
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import ConnectExistingCustomer from '../ConnectExistingCustomer.vue';
import gql from 'graphql-tag';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import moment from 'moment';
import WebRequestDetailInquiry from '../common/WebRequestDetailInquiry.vue';

export default {
  components: { ConnectExistingCustomer, WebRequestDetailInquiry },
  name: 'experienceDetail',
  data() {
    return {
      remarks: this.data.remarks,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dataGroup: [],
      visibleCompChirld: false,
    };
  },
  props: {
    visible: Boolean,
    data: Object,
    check: Boolean,
  },

  watch: {
    data() {
      this.remarks = this.data.remarks;
    },
  },

  computed: {
    status: {
      get() {
        if (this.data.handledAt) {
          this.setSatusOC('Handled');
          return { id: 'Handled', name: '対応済' };
        } else {
          this.setSatusOC('New');
          return { id: 'New', name: '未対応' };
        }
      },
      set(value) {
        this.setSatusOC(value);
      },
    },

    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format('YYYY/M/DD HH:mm');
    },
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions(['updateWebrequestOC']),
    ...mapMutations([
      'setCopyErrorText',
      'setSatusOC',
      'setRemarksOC',
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),

    save(id) {
      if (this.$refs.form.validate()) {
        this.setRemarksOC(this.remarks);
        this.updateWebrequestOC(id).then(() => {
          this.$emit('reset')
          this.closeDialog();
        });
      }
    },

    async addNewClientID(id) {
      const query = `
            mutation ($webRequestId: Int!) {
              createClientWebRequest(webRequestId: $webRequestId) {
                __typename
              }
            }
          `
      const variables = {
            webRequestId: parseInt(id),
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then((data) => {
          this.$store.dispatch('getWebrequestlistExperience');
          this.setAlertSuccess('更新しました。');
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    closeDialog() {
      this.remarks = this.data.remarks;
      this.$refs.form.reset()
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.btn-save{
  background: #1873D9 !important;
  border-radius: 4px;
  opacity: 1;
  min-width: 44px !important;
  height: 32px !important;
}
.t-btn--red-dark {
  width: 73px !important;
  height: 32px !important;
  font-size: 12px !important;
}
.boder_padding {
  padding: 10px 0 10px 20px;
  font-size: 12px !important;
  color: #000000;
}
::v-deep .boder_gray_padding {
  border-bottom: 1px solid #bbbbbb;
  padding: 10px 0 10px 20px;
  font-size: 12px !important;
  color: #000000;
}
.div-alert {
  background-color: #0b6786;
  border-radius: 10px;
  color: white;
  width: 500px;
  height: 46px;
  line-height: 46px;
  padding: 0 20px;
}
.btn-div-alert {
  background-color: #0b6786 !important;
  color: white;
  border: 1px solid white;
  font-size: 14px !important;
  min-width: 150px;
}
.btn-customer-details {
  background: #757575 !important;
  color: #fafafa !important;
  font-size: 12px !important;
}
::v-deep {
  .v-select.v-input--dense .v-select__selection--comma {
    font-size: 16px !important;
    color: #666666 !important;
  }
  .v-dialog {
    width: 1500px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
</style>
