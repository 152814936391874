<template>
  <v-dialog v-model="dialog">
    <v-form ref="form">
      <h1>新規資料請求を追加</h1>
      <v-radio-group
        v-model="row"
        row
        class="mt-4"
        @change="getChangeV1($event)"
        :mandatory="true"
      >
        <v-radio label="VSのみ" :value="0"></v-radio>
        <v-radio label="VMのみ" :value="1"></v-radio>
        <v-radio label="VM・VS" :value="2"></v-radio>
      </v-radio-group>
      <h6 for="">備考</h6>
      <v-text-field
        single-line
        :value="getRemarks"
        v-model="remarks"
      ></v-text-field>
      <div class="d-flex justify-end mt-2">
        <v-btn class="t-btn--red-dark mx-5" @click="closeDialog">
          <v-icon>mdi-close</v-icon>{{ $t('common.close') }}
        </v-btn>
        <v-btn class="t-btn--prm" @click="actionCreate()">
          {{ $t('common.save') }}
        </v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  name: 'AddedNewDocumentRequest',
  data() {
    return {
      remarks: '',
      forVs: false,
      forVm: false,
      valid: false,
      row: null,
      isShowDate: false,
      dates: [],
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapGetters(['getRemarks']),
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ');
      },
      set() {
        this.dates.join(' ~ ');
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    dataRemarks: {
      get() {
        return this.getRemarks;
      },
      set(value) {
        this.setRemarks(value);
      },
    },
  },

  methods: {
    ...mapMutations(['setRemarks', 'setNewDoc', 'setAlertSuccess']),
    ...mapActions(['createWebBrochureRequestToClient']),

    actionCreate() {
      const addNewDocReq = {
        clientId: parseInt(this.$route.params.id),
        remarks: this.remarks,
        forVs: this.forVs,
        forVm: this.forVm,
      };
      this.setNewDoc({ addNewDocReq: addNewDocReq });
      this.save();
      this.createWebBrochureRequestToClient();
    },

    getChangeV1(event) {
      if (event === 0) {
        this.forVs = true;
        this.forVm = false;
      } else if (event === 1) {
        this.forVs = false;
        this.forVm = true;
      } else {
        this.forVs = true;
        this.forVm = true;
      }
    },

    save() {
      this.$emit('close');
      this.row = null;
      this.remarks = null;
    },
    closeDialog() {
      this.$emit('close');
      this.row = null;
      this.remarks = null;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
h6 {
  font-size: 12px !important;
  font-weight: 600;
  color: #000 !important;
}
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 335px;
    padding: 20px;
    background-color: #fff !important;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 12px;
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 12px;
  }
  .v-radio .theme--light {
    color: #757575;
    font-size: 14px !important;
    font-weight: bold;
  }

  .v-btn {
    font-size: 14px !important;
    height: 32px !important;
    i {
      font-size: 14px;
    }
  }
  .t-btn--red-dark {
    width: 72px !important;
  }
  .t-btn--prm {
    width: 68px !important;
  }
  .v-text-field__slot {
    input {
      font-size: 14px;
      color: #000 !important;
      font-weight: 500;
    }
  }
}
</style>
