<template>
  <v-dialog v-model="dialog">
    <v-form ref="form">
      <h1>既存顧客に結びつける</h1>
      <h6 for="" class="mt-3">顧客番号</h6>
      <v-text-field
        single-line
        :value="toclientIdInput"
        v-model="toclientIdInput"
        :rules="rules.number"
      ></v-text-field>
      <div class="d-flex justify-end mt-2">
        <v-btn class="t-btn--red-dark mx-5" @click="closeDialog">
          <v-icon>mdi-close</v-icon>{{ $t('common.close') }}
        </v-btn>
        <v-btn class="t-btn--prm" @click="actionCreate()">
          紐付け
        </v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { checkNumber } from '@/utils/validate';
export default {
  name: 'DeleteDuplicatedClient',
  data() {
    return {
      toclientIdInput:null,
      forVs: false,
      forVm: false,
      valid: false,
      row: null,
      isShowDate: false,
      dates: [],
      rules: {
            number: [
            v => !!v || '必須項目です。',
            v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
            v =>(v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32')
            ],
            numberCurrency: [
            v =>
                (v && checkNumber(v.replace(/¥|,/g, ''))) ||
                !v ||
                this.$t('rules.numberInvalid'),
            v =>
                (v && parseInt(v.replace(/¥|,/g, '')) <= 2147483647) ||
                !v ||
                this.$t('rules.maximumNumberInt32'),
            ], 
        },
    };
  },
  props: {
    visible: Boolean,
    clientId: String
  },
  computed: {
    ...mapGetters([]),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

  },

  methods: {
    ...mapMutations([]),
    ...mapActions(['deleteDuplicatedClient']),

    actionCreate() {
      const deleteData = {
        clientId: parseInt(this.clientId),
        toClientId: parseInt(this.toclientIdInput),
      };
      if(this.$refs.form.validate()) {
        this.save();
        this.deleteDuplicatedClient(deleteData)
      }
    },
    save() {
      this.$refs.form.reset();
      this.$emit('close');
    },
    closeDialog() {
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
h6 {
    text-align: left;
    font: normal normal 600 10px/15px Noto Sans;
    font-size:10px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 335px;
    padding: 20px;
    background-color: #fff !important;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 12px;
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 12px;
  }
  .v-radio .theme--light {
    color: #757575;
    font-size: 14px !important;
    font-weight: bold;
  }

  .v-btn {
    font-size: 14px !important;
    height: 32px !important;
    i {
      font-size: 14px;
    }
  }
  .t-btn--red-dark {
    width: 72px !important;
  }
  .t-btn--prm {
    width: 68px !important;
  }
  .v-text-field__slot {
    input {
      font-size: 14px;
      color: #000 !important;
      font-weight: 500;
    }
  }
}
</style>
