<template>
  <document-request-detail-corporation v-if="isCompany === true" :visible="true" @close="close"  />
  <document-request-detail-individual v-else-if="isCompany === false" :visible="true" @close="close" />
</template>

<script>
import DocumentRequestDetailCorporation from './documentRequestDetailCorporation.vue'
import documentRequestDetailIndividual from './documentRequestDetailIndividual.vue'
export default {
  components: { documentRequestDetailIndividual, DocumentRequestDetailCorporation },
  props: {
    id: Number,
  },
  computed: {
    isCompany () {
      return this.$store.getters.webrequestNotIscopanyDocumentRequestInfo?.isCompany
    }
  },
  async mounted() {
    await this.$store.dispatch(
      'getWebrequestNotIscopanyDocumentRequestList',
      this.id)
  },
  methods: {
    close (){
      this.$emit('close')
    }
  }
}
</script>