<template>
  <div>
    <div class="d-flex justify-space-between mt-5 mb-2">
      <h2 class="text--title">広告施策</h2>
      <div class="d-flex justify-end">
        <v-select
          :items="campaignList"
          item-text="name"
          item-value="id"
          label="広告施策追加"
          outlined
          dense
          v-model="campaignId"
          class="v-select-custom--label"
          :disabled="!checkPerUser"
        ></v-select>
        <v-btn
          small
          class="btn-crm-primary mx-3"
          @click="createCamApp(false)"
          :disabled="!checkPerUser"
          >{{
            $t('task_29786.ProspectInformationTab.addition')
          }}</v-btn
        >
        <v-btn
          small
          class="btn-crm-primary"
          @click="createCamApp(true)"
          :disabled="!checkPerUser"
          >{{
            $t('task_29786.ProspectInformationTab.AddedWithReaction')
          }}</v-btn
        >
      </div>
    </div>
    <v-card>
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          class: 'mb-10 v-custom-table',
        }"
        ref="table"
        :itemsPerPage="500"
        :headers="headers"
        :items="items"
        :funReset="loadData"
        :hideFooter="true"
        :disableSort="true"
        :total="totalItems"
      >
        <template v-slot:[`item.responseAt`]="{ item }">
          <v-menu bottom offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="item.responseAt ? '#F5511E' : '#9E9E9E'"
                class="btnStatus"
                v-bind="attrs"
                v-on="on"
                ><span>
                  {{ item.responseAt ? '反応有' : '反応無' }}
                </span>
              </v-btn>
            </template>

            <v-list>
              <div class="px-2 div-select-dialog">
                <v-select
                  dense
                  class="my-1"
                  :value="!!item.responseAt"
                  @change="changeStatus($event, item.id)"
                  item-value="showStatusPro"
                  item-text="name"
                  :items="responseItems"
                  :disabled="!checkPerUser"
                >
                </v-select>
              </div>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:[`item.campaign.name`]="{ item }">
          {{ item.campaign ? item.campaign.name : '' }}
        </template>
        <template v-slot:[`item.campaign.groups`]="{ item }">
          {{ item.campaign ? item.campaign.groups.join(', ') : '' }}
        </template>
        <template v-slot:[`item.attachment1`]="{ item }">
          <v-btn
            icon
            v-if="item.campaign.attachment1Url"
            @click="
              $download(item.campaign.attachment1Url)
            "
          >
            <i class="fas fa-file-download btn-download"></i>
          </v-btn>
        </template>
        <template v-slot:[`item.attachment2`]="{ item }">
          <v-btn
            icon
            v-if="item.campaign.attachment2Url"
            @click="
              $download(item.campaign.attachment2Url)
            "
          >
            <i class="fas fa-file-download btn-download"></i>
          </v-btn>
        </template>
        <template v-slot:[`item.attachment3`]="{ item }">
          <v-btn
            icon
            v-if="item.campaign.attachment3Url"
            @click="
              $download(item.campaign.attachment3Url)
            "
          >
            <i class="fas fa-file-download btn-download"></i>
          </v-btn>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-btn
            class="t-btn--red-dark"
            icon
            @click="deleteCamApp(item.id)"
            :disabled="!checkPerUser"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.detail`]="{ item }">
          <v-btn
            class="btn-crm-primary btn-edit"
            icon
            :disabled="!checkPerUser"
            @click="
              (detailDialog = true),
                (idPolicy = item.campaign.id)
            "
          >
            <v-icon x-large>mdi-pencil-circle</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{
            new Date(item.createdAt)
              .toISOString()
              .substr(0, 10)
              .replace(/-/g, '/')
          }}
        </template>
      </Table>
    </v-card>
    <!-- Dialog -->
    <AdvertisingPoliciesDetailDialog
      v-if="detailDialog"
      @close="detailDialog = false"
      :idPolicy="idPolicy"
    />
  </div>
</template>

<script>
import
{
  LIST_TABLE_END,
} from '@/api/graphql/customer/response/detail/prospectInformation/prospectInformation.js';
import {
  DELETE_CAMAPP_VSINTEREST,
} from '@/api/graphql/customer/CustomerDetail';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import AdvertisingPoliciesDetailDialog from '@/views/advertising/policies/advertisingPoliciesDetailDialog.vue';
import Table from '@/components/Table/index.vue';
import gql from 'graphql-tag';
import { mapMutations } from 'vuex';

export default {
  components: {
    Table,
    AdvertisingPoliciesDetailDialog
  },
  props: {
    checkPerUser: Boolean,
    clientId: Number,
  },
  data () {
    return {
      campaignId: null,
      campaignList: [],
      items: [],
      totalItems: 0,
      idPolicy: null,
      detailDialog: false,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '広告施策名',
          value: 'campaign.name',
          width: '180px',
        },
        {
          text: '広告施策タグ',
          value: 'campaign.groups',
          width: '123px',
        },
        {
          text: '反応',
          value: 'responseAt',
          width: '49px',
        },
        {
          text: '追加日',
          value: 'createdAt',
          width: '76px',
        },
        {
          text: '説明',
          value: 'campaign.note',
          width: '257px',
        },
        {
          text: '発送資料',
          value: 'attachment1',
          width: '48px',
        },
        {
          text: '発送資料',
          value: 'attachment2',
          width: '48px',
        },
        {
          text: '発送資料',
          value: 'attachment3',
        },
        {
          text: '削除',
          value: 'delete',
          width: '24px',
        },
        {
          text: '詳細',
          value: 'detail',
          width: '24px',
        },
      ]
    },
    responseItems() {
      return [
        { showStatusPro: true, name: '反応有' },
        { showStatusPro: false, name: '反応無' },
      ]
    },
    filter () {
      return {
        clientId: this.clientId,
      }
    }
  },
  methods: {
    async reload () {
        await this.$refs.table.reset();
    },
    ...mapMutations([
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setAlertSuccess',
      'setCopyErrorText',
      'setAlertError',
    ]),
    async createCamApp(withResponse) {
      if (this.campaignId) {
        const data = {
          clientId: this.clientId,
          campaignId: this.campaignId,
          hasResponse: withResponse,
        };
        await this.$store.dispatch('createCampaignApplyResponsePro', {data});
        await this.reload()
      } else {
        this.setAlertError('追加する広告施策を選んで下さい');
      }
    },
    async loadData(params) {
      const variables = {
        filter: this.filter,
        pagination: params.pagination
      }
      const result = await this.$apollo.query({
        query: gql`${ LIST_TABLE_END }`,
        variables,
        fetchPolicy: 'no-cache'
      });
      this.items = result.data.campaignApplyResponseList.items
      this.totalItems = result.data.campaignApplyResponseList.total
    },

    deleteCamApp(id) {
      if (id) {
        this.$swal
          .fire({
            title: '削除しますか？',
            showCancelButton: true,
            confirmButtonText: '削除',
            confirmButtonColor: '#ff5252',
            cancelButtonText: '閉じる',
            cancelButtonColor: '#1976d2',
            reverseButtons: true,
          })
          .then(async result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.setLoadingOverlayShow();
              const variables = {
                id: parseInt(id),
              };
              await this.$apollo
                .mutate({
                  mutation: gql`${DELETE_CAMAPP_VSINTEREST}`,
                  variables: variables,
                  fetchPolicy: 'no-cache',
                })
                .then(async data => {
                  this.setLoadingOverlayHide();
                  if (data.data.deleteCampaignApplyResponse) {
                    this.setAlertSuccess('削除しました。');
                  }
                  await this.reload()
                })
                .catch(async error => {
                  this.setLoadingOverlayHide();
                  this.setCopyErrorText(
                    getCopyErrorTextView(
                      DELETE_CAMAPP_VSINTEREST,
                      variables,
                      error.graphQLErrors,
                    ),
                  );
                  const errorTmp = await handlErrorView(
                    error.graphQLErrors,
                    this.setPermissionUser,
                    this.setRoleAdminUser,
                  );
                  if (errorTmp) {
                    this.setAlertError(errorTmp, { root: true });
                  } else {
                    this.setAlertError('削除に失敗しました');
                  }
                  await this.reload()
                });
            }
          });
      }
    },

    async changeStatus(event, id) {
      var status = null;
      if (event) {
        status = true;
      } else {
        status = false;
      }
      const query = `
            mutation ($id: Int!, $hasResponse: Boolean!) {
              updateCampaignApplyResponse(id: $id, response: $hasResponse) {
                id
              }
            }
          `;
      const variables = {
        id: parseInt(id),
        hasResponse: status,
      };
      await this.$apollo
        .mutate({
          mutation: gql`
            ${query}
          `,
          variables: variables,
        })
        .then(data => {
          this.$store.dispatch('proDataTableEnd');
          this.reload()
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
  },
  apollo: {
    campaignList: {
      query: gql`
            query {
              campaignList(filter: {
                finished:false
              }) {
                items {
                  id name
                }
              }
            }
          `,
      fetchPolicy: 'no-cache',
      update: data => {
        return data.campaignList.items
      }
    },
  }
}
</script>

<style lang="scss" scoped>

h2 {
  font-size: 20px !important;
  font-weight: 600;
  color: #0b6786;
}
.btnStatus {
  width: 70px;
  color: white;
  border-radius: 6px;
  text-align: right;
}
</style>