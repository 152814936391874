<template>
  <v-simple-table
    fixed-header
    class="table2"
    max-height="300px"
    dense
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th
            v-for="(item, i) in headerVisitReservation"
            :key="i"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="items && items.length > 0">
          <tr
            v-for="(item, index) in items"
            :key="index"
          >
            <td>
              <v-chip
                :color="
                  item.handledAt === null
                    ? '#DB3069'
                    : '#616161'
                "
                class="btn-status"
                label
                small
                text-color="white"
              >
                {{
                  item.handledAt === null
                    ? '未対応'
                    : '対応済'
                }}
              </v-chip>
            </td>
            <td>
              {{ item.createdAt | formatMonthDate }}
            </td>
            <td>
              {{ item.firstDate | formatMonthDate }}
              {{ item.firstTime | formatTime }}
            </td>
            <td>
              {{ item.secondDate | formatMonthDate }}
              {{ item.secondTime | formatTime }}
            </td>
            <td>
              <v-btn
                class="btn-crm-primary btn-edit"
                icon
                @click="$emit('detailClick', item)"
              >
                <v-icon x-large>mdi-pencil-circle</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td class="text-center text-heading" colspan="5">
            {{ $t('rules.noData') }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data () {
    return {
      headerVisitReservation: [
        {
          text: 'ステータス',
          value: 'handledAt',
          width: '63px',
          id: 'id',
        },
        {
          text: '受信日',
          value: 'createdAt',
          width: '70px',
          id: 'id',
        },
        {
          text: '第一希望日',
          value: 'FirstChoiceDate',
          width: '150px',
          id: 'id',
        },
        {
          text: '第二希望日',
          value: 'SecondChoiceDate',
          width: '150px',
          id: 'id',
        },
        {
          text: '詳細',
          value: 'edit',
          align: 'right',
          id: 'id',
        },
      ],
    }
  }
}
</script>