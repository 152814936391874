<template>
  <div v-if="getClientBasicInfoPro">
    <customer-details>
      <!-- Button top -->
      <template v-slot:btnPrope>
        <!-- if type === MEMBER and VSINTEREST -->
        <v-btn
          v-if="typeShow !== TYPE_CLIENT.TENTATIVE"
          class="t-btn--prm mr-5 ml-2"
          @click="actionUpdatePro()"
          :disabled="!checkPerUser"
        >
          {{ $t('task_29786.keep') }}
        </v-btn>
        <!-- end if type === MEMBER -->

        <!-- if type === TENTATIVE -->
        <v-btn
          v-if="typeShow === TYPE_CLIENT.TENTATIVE"
          class="t-btn--prm mr-5 ml-2"
          @click="actionUpdate()"
          :disabled="!checkPerUser"
        >
          {{ $t('task_29786.keep') }}
        </v-btn>
        <!-- end if type === TENTATIVE -->
      </template>
      <!-- End Button top -->

      <!-- Title -->
      <template v-slot:headerTitle v-if="typeShow === TYPE_CLIENT.TENTATIVE">
        <span class="page-title-main">:折衝顧客</span>
      </template>
      <!-- End Title -->

      <!-- Content -->
      <template v-slot:default>
        <!-- if type === MEMBER -->
        <template v-if="typeShow === TYPE_CLIENT.MEMBER">
          <div>
            <h2 class="text--title mb-2 mt-6">見込客情報</h2>
            <v-card outlined class="pb-8">
              <div class="mx-8">
                <v-checkbox
                  v-model="getClientBasicInfoPro.doNotContact"
                  @change="checkoutBox($event)"
                  label="折衝不可"
                  :disabled="!checkPerUser"
                  class="pb-3 checkbox"
                ></v-checkbox>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :value="getClientBasicInfoPro.purchaseRateId"
                      @change="changePurcha($event)"
                      class="w-50"
                      label="購入評価"
                      :items="getEnumPur"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <div v-for="item in listDate1" :key="item.id" class="w-20">
                      <v-menu
                        v-model="menuDate1[listDate.indexOf(item)]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="!checkPerUser"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex justify-start border-hiden">
                            <v-text-field
                              readonly
                              label="反響日"
                              :value="getClientBasicInfoPro.lastResponseDate"
                              @change="changeLastRes($event)"
                              v-on="on"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="getClientBasicInfoPro.lastResponseDate"
                          :first-day-of-week="0"
                          :locale="$i18n.locale"
                          scrollable
                          @input="menuDate1[listDate.indexOf(item)] = false"
                          @change="changeLastRes($event)"
                          class="v-date-picker-custom"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :value="getClientBasicInfoPro.furtherPurchaseRateId"
                      @change="changeFurther($event)"
                      class="w-50"
                      label="買増評価"
                      :items="getEnumPur"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :value="getClientBasicInfoPro.seasonsInterested"
                      @change="changeSeasons($event)"
                      label="希望シーズン"
                      class="w-33"
                      :items="getEnumSeason"
                      item-value="name"
                      item-text="name"
                      multiple
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :value="getClientBasicInfoPro.renewPurchaseRateId"
                      @change="changeRenew($event)"
                      class="w-50"
                      label="更新評価"
                      :items="getEnumPur"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-combobox
                      label="興味のある施設"
                      class="w-70"
                      :value="getClientBasicInfoPro.facilitiesInterested"
                      @change="changeFaci($event)"
                      :items="getFacilityList"
                      item-text="name"
                      multiple
                      :disabled="!checkPerUser"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-combobox
                      :value="negotiationExitReasonFormat"
                      @change="changeNegotiationExitReason($event)"
                      multiple
                      class=""
                      label="検討離脱理由"
                      :items="getEnumNego.map(item => item.name)"
                      :disabled="!checkPerUser"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <!-- Manager -->
                <h3 class="pb-3 my-4">担当者</h3>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :value="getClientBasicInfoPro.pic"
                      @change="changeID($event)"
                      class="w-50"
                      label="営業担当者"
                      :items="itemsData.picList"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                </v-row>

                <div>
                  <v-card class="mt-4 w-45">
                    <Table
                      :attr="{
                        dense: true,
                        'no-data-text': $t('rules.noData'),
                        'item-key': 'id',
                      }"
                      ref="tableClientChange"
                      :itemsPerPage="10"
                      :itemsPerPageOptions="[5, 10, 20, 50, 100, 200, 500]"
                      :headers="headerManager"
                      :total="totalCount"
                      :items="getClientChange"
                      :funReset="loadList"
                      :disableSort="true"
                    >
                      <template v-slot:[`item.changedAt`]="{ item }">
                        {{ item.changedAt | formatDate }}
                      </template>
                    </Table>
                  </v-card>
                </div>
                <!-- End Manager -->
              </div>
            </v-card>
          </div>
          <!-- WEB application history -->
          <div class="web-request-lists">
            <h2 class="text--title mt-5 mb-2">WEB申込履歴</h2>
            <v-card>
              <!-- Document request -->
              <prospect-info-document-request-table
                :checkPerUser="checkPerUser"
                :items="getProBrochures"
              />
              <!-- End Document request -->

              <!-- Visit reservation -->
              <div class="mx-2">
                <h3 class="text--title text-16px ml-5 my-3">来店予約</h3>

                <v-card>
                  <visit-list-for-client
                    :items="getProVisits"
                    @detailClick="
                      checkPerUser ? showDetail($event.id) : ''
                    "
                  />
                </v-card>
              </div>
              <!-- End Visit reservation -->

              <!-- Online consultation -->
              <div class="mx-2">
                <h3 class="text--title text-16px ml-5 my-3">オンライン相談</h3>

                <v-card>
                  <online-consulting-list-for-client
                    :items="getProOnlines"
                      @detailClick="
                        checkPerUser ? setData($event) : '',
                          (visible = 5),
                          (itemID = $event.id)
                      
                      "
                  />
                </v-card>
              </div>
              <!-- End Online consultation -->

              <!-- Experience Accommodation -->
              <div class="mx-2 pb-5">
                <p class="text--title text-16px ml-5 my-3">体験宿泊</p>

                <v-card>
                  <trial-stay-list-for-client
                    :items="getProTrials"
                    @detailClick="
                      checkPerUser
                        ? ((visible = 6),
                          getWebReqEditFacility($event.id))
                        : ''
                    "
                  />
                </v-card>
              </div>
              <!-- End Experience Accommodation -->
            </v-card>
            <div class="Advertising">
              <prospect-campaign-table :clientId="clientId" :checkPerUser="checkPerUser" />
            </div>
          </div>
        </template>
        <!-- end if type === MEMBER -->

        <!-- if type === TENTATIVE -->
        <template v-if="typeShow === TYPE_CLIENT.TENTATIVE">
          <div>
            <div class="alert-negotiating-customer">
              <i
                class="fas fa-exclamation-triangle"
                style="font-size: 22px; color: #ffffff"
              />
              <span class="alert-negotiating-customer-title"
                >この顧客は折衝顧客です</span
              >
              <v-btn
                class="alert-negotiating-customer-btn"
                @click="changeStatusClient($route.params.id, 0)"
              >
                契約作成顧客に切替
              </v-btn>
            </div>
            <h2 class="text--title mb-2 mt-6">見込客情報</h2>
            <v-card outlined class="pb-8 mb-4">
              <div class="mx-8">
                <v-checkbox
                  v-model="finishData.doNotContact"
                  label="折衝不可"
                  :disabled="!checkPerUser"
                  class="pb-3 checkbox"
                ></v-checkbox>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="finishData.purchaseRateId"
                      class="w-50"
                      label="購入評価"
                      :items="getEnumPur"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <div v-for="item in listDate1" :key="item.id" class="w-20">
                      <v-menu
                        v-model="menuLastResponseDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="!checkPerUser"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex justify-start border-hiden">
                            <v-text-field
                              readonly
                              label="反響日"
                              v-model="finishData.lastResponseDate"
                              v-on="on"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="finishData.lastResponseDate"
                          :first-day-of-week="0"
                          :locale="$i18n.locale"
                          scrollable
                          @input="menuLastResponseDate = false"
                          class="v-date-picker-custom"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="finishData.furtherPurchaseRateId"
                      class="w-50"
                      label="買増評価"
                      :items="getEnumPur"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="finishData.seasonsInterested"
                      label="希望シーズン"
                      class="w-33"
                      :items="getEnumSeason"
                      item-value="name"
                      item-text="name"
                      multiple
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="finishData.renewPurchaseRateId"
                      class="w-50"
                      label="更新評価"
                      :items="getEnumPur"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-combobox
                      label="興味のある施設"
                      class="w-70"
                      v-model="finishData.facilitiesInterested"
                      :items="getFacilityList"
                      item-text="name"
                      multiple
                      :disabled="!checkPerUser"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-combobox
                      v-model="finishData.negotiationExitReason"
                      multiple
                      class=""
                      label="検討離脱理由"
                      :items="getEnumNego.map(item => item.name)"
                      :disabled="!checkPerUser"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <h3 class="pb-3 my-4 mt-6">担当者</h3>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="finishData.picId"
                      class="w-50"
                      label="営業担当者"
                      :items="itemsData.picList"
                      item-value="id"
                      item-text="name"
                      :disabled="!checkPerUser"
                    ></v-select>
                  </v-col>
                </v-row>
                <div
                  class="t-wrapper elevation-3 rounded"
                  style="max-width: 504px"
                >
                  <Table
                    :attr="{
                      dense: true,
                      'no-data-text': $t('rules.noData'),
                      'item-key': 'id',
                    }"
                    ref="tableClientChange"
                    :itemsPerPage="10"
                    :itemsPerPageOptions="[5, 10, 20, 50, 100, 200, 500]"
                    :headers="headerManager"
                    :total="totalCount"
                    :items="getClientChange"
                    :funReset="loadList"
                    :disableSort="true"
                  >
                    <template v-slot:[`item.changedAt`]="{ item }">
                      {{ item.changedAt | formatDate }}
                    </template>
                  </Table>
                  <!-- <h4 class="px-2 pb-3 text-10px">
                      他のスタッフへの要共有事項
                    </h4> -->
                </div>
              </div>
            </v-card>
            <!-- WEB application history -->
            <div class="web-request-lists">
              <h2 class="text--title mt-5 mb-2">WEB申込履歴</h2>
              <v-card>
                <prospect-info-document-request-table
                  :checkPerUser="checkPerUser"
                  :items="getProBrochures"
                />
                <!-- Document request -->
                <!-- End Document request -->

                <!-- Visit reservation -->
                <div class="mx-2">
                  <h3 class="text--title text-16px ml-5 my-3">来店予約</h3>

                  <v-card>
                    <visit-list-for-client
                      :items="getProVisits"
                      @detailClick="
                        checkPerUser ? showDetail($event.id) : ''
                      "
                    />
                  </v-card>
                </div>
                <!-- End Visit reservation -->

                <!-- Online consultation -->
                <div class="mx-2">
                  <h3 class="text--title text-16px ml-5 my-3">
                    オンライン相談
                  </h3>

                  <v-card>
                    <online-consulting-list-for-client
                      :items="getProOnlines"
                        @detailClick="
                          checkPerUser ? setData($event) : '',
                            (visible = 5),
                            (itemID = $event.id)
                        
                        "
                    />
                  </v-card>
                </div>
                <!-- End Online consultation -->

                <!-- Experience Accommodation -->
                <div class="mx-2 pb-5">
                  <p class="text--title text-16px ml-5 my-3">体験宿泊</p>

                  <v-card>
                    <trial-stay-list-for-client
                      :items="getProTrials"
                      @detailClick="
                        checkPerUser
                          ? ((visible = 6),
                            getWebReqEditFacility($event.id))
                          : ''
                      "
                    />
                  </v-card>
                </div>
                <!-- End Experience Accommodation -->
              </v-card>
              <div class="Advertising">
                <prospect-campaign-table :clientId="clientId" :checkPerUser="checkPerUser" />
              </div>
            </div>
          </div>
        </template>
        <!-- end if type === TENTATIVE -->

        <!-- if type === VSINTEREST -->
        <template v-if="typeShow === TYPE_CLIENT.VSINTEREST">
          <div>
            <!-- <h2 class="text--title mb-2 mt-6">見込客情報</h2> -->
            <v-alert
              dense
              color="#CB396C"
              class="mt-5 white--text font-weight-bold"
              max-width="700"
            >
              <v-row align="center">
                <v-col>
                  <v-icon dark left> mdi-alert-outline </v-icon>
                  VS資料請求のみの顧客です
                </v-col>
                <v-col class="d-flex">
                  <v-btn
                    dense
                    outlined
                    class="white--text"
                    @click="redirectNo43CRM"
                  >
                    既存顧客に結びつける
                  </v-btn>
                  <v-btn
                    outlined
                    dense
                    class="ml-2 white--text"
                    @click="changeStatusClient($route.params.id, 1)"
                  >
                    折衝顧客に切替
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </div>
          <!-- WEB application history -->
          <div>
            <h2 class="text--title mt-5 mb-2">WEB申込履歴</h2>
            <v-card>
              <!-- Document request -->
              <prospect-info-document-request-table
                :checkPerUser="checkPerUser"
                :items="getListBrochureVsinterest"
                hide-add
              />
              <!-- End Document request -->
            </v-card>

            <div class="Advertising">
              <prospect-campaign-table :clientId="clientId" :checkPerUser="checkPerUser" />
            </div>
          </div>
          <delete-duplicated-Client
            :visible="visible === 2"
            @close="visible = -1"
            :clientId="clientIdForDelete"
          >
          </delete-duplicated-Client>
        </template>
        <!-- end if type === VSINTEREST -->
      </template>
      <!-- end Content -->
    </customer-details>

    <WebVisitReservationDetail
      v-if="checkPerUser && visible === 4"
      :visible="visible === 4"
      :id="idDetail"
      @fetchList="proVisits"
      @close="visible = false"
    >
    </WebVisitReservationDetail>

    <experience-detail
      v-if="checkPerUser"
      :check="check"
      :data="data"
      :visible="visible === 5"
      @close="visible = -1"
    />

    <FacilityListDetail
      v-if="checkPerUser"
      :visible="visible === 6"
      @close="visible = -1"
    >
    </FacilityListDetail>
    <!-- End Dialog -->
  </div>
</template>
<script>
import CustomerDetails from '../CustomerDetails.vue';
import DeleteDuplicatedClient from './DeleteDuplicatedClient.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { TYPE_CLIENT } from '@/constants/enum';
import { CLIENT_BASIC_INFO_CRRENT } from '@/api/graphql/basicInformation/basicInformation';
import {
  WAITLIST_BROCHURE,
} from '@/api/graphql/customer/CustomerDetail';
import moment from 'moment';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import gql from 'graphql-tag';
import WebVisitReservationDetail from '../../../../dashboard/webPageManage/VisitReservation/WebVisitReservationDetail.vue';
import ExperienceDetail from '../../../../dashboard/webPageManage/experience/experienceDetail.vue';
import { WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION } from '@/api/graphql/dashboard/webPageManage/experience/index.js';
import FacilityListDetail from '../../../../dashboard/webPageManage/facility/FacilityListDetail.vue';
import Table from '@/components/Table/index.vue';
import TrialStayListForClient from './TrialStayListForClient.vue';
import VisitListForClient from './VisitListForClient';
import OnlineConsultingListForClient from './OnlineConsultingListForClient';
import ProspectCampaignTable from './ProspectCampaignTable.vue';
import ProspectInfoDocumentRequestTable from './ProspectInfoDocumentRequestTable.vue'

export default {
  components: {
    Table,
    DeleteDuplicatedClient,
    CustomerDetails,
    WebVisitReservationDetail,
    ExperienceDetail,
    FacilityListDetail,
    TrialStayListForClient,
    VisitListForClient,
    OnlineConsultingListForClient,
    ProspectCampaignTable,
    ProspectInfoDocumentRequestTable
  },
  name: 'customer-details-overview',
  data() {
    return {
      itemID: null,
      check: true,
      idDetail: null,
      negotiationExitReasoned: null,
      currentId: null,
      data: {},
      dataEx: {},
      idContract: null,
      idPolicy: null,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      menuDate1: [],
      listDate: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      listDate1: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      TYPE_CLIENT,
      clientIdForDelete: null,
      doNotContact: false,
      updateData: { doNotContact: false },
      purchaseRateId: null,
      furtherPurchaseRateId: null,
      renewPurchaseRateId: null,
      negotiationExitReason: '',
      lastResponseDate: '',
      seasonsInterested: '',
      facilitiesInterested: '',
      picId: '',
      campaignId: null,
      responseSelected: false,
      itemsCamList: [],
      dataCamApp: {},
      response: false,
      checkbox: false,
      visible: -1,
      headerManager: [
        {
          text: '営業担当開始日',
          value: 'changedAt',
          width: '25%',
        },
        {
          text: '名前',
          value: 'valueAfter',
        },
      ],
      itemsPerPage: 10,
      totalCount: 0,
      ready: false,
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      Manager: [],

      // check type show template - no 42 東急太郎 : VS資料請求のみ
      typeShow: null,
      typeClient: this.$route.query.typeClient,
      idClient: parseInt(this.$route.params.id),
      finishData: {
        type: null,
        doNotContact: false,
        purchaseRateId: null,
        furtherPurchaseRateId: null,
        renewPurchaseRateId: null,
        picId: null,
        pic: null,
        lastMeetDate: null,
        lastMeetMethod: null,
        lastMeetAttendant: null,
        lastResponseDate: null,
        seasonsInterested: null,
        facilitiesInterested: null,
        negotiationExitReason: null,
        // negotiationExitReasonOthers: null,
      },

      menuLastMeetDate: false,
      menuLastResponseDate: false,
      itemsData: {
        purchaseRateList: [],
        seasonInterestList: [],
        facilityList: [],
        negotiationExitReasonList: [],
        staffList: [],
        clientChangeHistoryList: [],
        picList: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getClientBasicInfoPro',
      'getEnumPur',
      'getEnumSeason',
      'getFacilityList',
      'getEnumNego',
      'getClientChange',
      'getProBrochures',
      'getProVisits',
      'getProOnlines',
      'getProTrials',
      'getSelectTable',
      'getShowPro',
      'getShowProVsinterest',
      'getListBrochureVsinterest',
      'getListAdvertisingMeasures',
      'getArrCampaignApplyResponseListVsinterest',
      // 'getCampaignPro',
    ]),

    clientId () {
      return Number(this.$route.params.id)
    },
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },

    negotiationExitReasonFormat() {
      if (this.getClientBasicInfoPro.negotiationExitReason) {
        return this.getClientBasicInfoPro.negotiationExitReason.split(',');
      } else {
        return '';
      }
    },

    dataNegotiationExitReasonOthers: {
      get() {
        if (
          this.getEnumNego.find(
            v => v.name === this.finishData.negotiationExitReason,
          )
        ) {
          return null;
        } else {
          return this.finishData.negotiationExitReason;
        }
      },
      set(value) {
        this.finishData.negotiationExitReason = value;
      },
    },
  },
  created() {
    this.actionStaffList().then(pic => {
      this.itemsData.picList.push({ id: null, name: '指定なし' });
      pic.forEach(element => {
        this.itemsData.picList.push({ id: element.id, name: element.name });
      });
    });
    // check type show template : type VSINTEREST
    if (this.idClient) {
      this.getUnserInfoClientCurrent(this.idClient);
    }
  },
  async mounted() {
    this.clientIdForDelete = this.$route.params.id;
    if (this.typeClient !== TYPE_CLIENT.VSINTEREST) {
      this.proClientBasicInfo(parseInt(this.$route.params.id)).then(
        async clientBasicInfo => {
          this.finishData = {
            ...clientBasicInfo,
          };
          if (clientBasicInfo.negotiationExitReason) {
            this.finishData.negotiationExitReason = clientBasicInfo.negotiationExitReason.split(
              ',',
            );
          }
        },
      );
      this.enumPurchaseRateList();
      this.enumSeasonInterestList();
      this.proFacilityList();
      this.enumNegotiationList();
      this.proStaffList();
      this.ready = true;
      if (this.$refs.tableClientChange) {
        await this.$refs.tableClientChange.reset();
      }
      this.proBrochures();
      this.proVisits();
      this.proOnlines();
      this.proTrials();
      this.proListSelect();
      this.getActionStaffList({
        take: this.itemsPerPage,
        skip: this.itemStart,
      });
    }
  },
  watch: {
    getProOnlines() {
      this.check = !this.check;
      if (this.itemID) {
        this.setData({ id: parseInt(this.itemID) });
      }
    },
  },
  methods: {
    reloadList(value, id) {
      const newArr = this.webRequestlistDocumentRequestlistNull;
      const objIndex = newArr.findIndex(e => e.id === id);
      newArr[objIndex].client.pic.id = value.client.pic.id;
      newArr[objIndex].client.pic.name = value.client.pic.name;
      this.setWebRequestlistDocumentRequestListNull(newArr);
    },
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'getActionStaffList',
      'proClientBasicInfo',
      'enumPurchaseRateList',
      'enumSeasonInterestList',
      'proFacilityList',
      'enumNegotiationList',
      'proStaffList',
      'proClientChangeHistoryList',
      'proBrochures',
      'proVisits',
      'proOnlines',
      'proTrials',
      'proListSelect',
      'getUpdateClient',
      'getWebrequestNotIscopanyDocumentRequestList',
      'actionUpdateClient',
      'getWebReqEditVisitreservation',
      'getWebRequestListVisitreservation',
      'getWebReqEditFacility',
      'actionStaffList',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setEnumPur',
      'setEnumSeason',
      'setFacilityList',
      'setEnumNego',
      'setStaffList',
      'setClientChange',
      'setProBrochures',
      'setProOnlines',
      'setProTrials',
      'setCampaignApply',
      'setCampaignApplyListPro',
      'setSelectTable',
      'setDataUpdatePro',
      'setListBrochureVsinterest',
      'setListAdvertisingMeasures',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setPermissionUser',
      'setRoleAdminUser',
      'setWebReqEditVisitreservation',
    ]),
    async showDetail(id) {
      this.idDetail = id;
      this.setWebReqEditVisitreservation({});
      this.visible = 4;
    },
    changeBtn(item) {
      window.open('/web-manage/visit-reservation?idDetail=' + item.id);
    },
    async setData(item) {
      const variables = {
        id: item.id,
      };
      this.$apollo
        .query({
          query: gql`
            ${WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          this.data = data.data.webRequest;
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
      // this.visible = 1
      // this.itemID = item.id
    },
    async changeStatusClient(id, type) {
      const query = `
            mutation ($clientId: Int!, $type: ClientType!) {
              updateClient(id: $clientId, data: { type: $type }) {
                id
                type
              }
            }
          `;
      const variables = {
        clientId: parseInt(id),
        type: type === 0 ? 'MEMBER' : 'TENTATIVE',
      };
      await this.$apollo
        .mutate({
          mutation: gql`
            ${query}
          `,
          variables: variables,
        })
        .then(data => {
          this.setAlertSuccess('更新しました。');
          this.$router.push({
            name: 'prospect-information',
            params: { id: data.data.updateClient.id },
            query: { typeClient: data.data.updateClient.type },
          });
          location.reload();
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async loadList(variables) {
      if (variables) {
        const { total } = await this.proClientChangeHistoryList({
          take: variables.pagination.take,
          skip: variables.pagination.skip,
        });
        this.totalCount = total;
      }
    },

    async getNumOfPage(pagination) {
      this.numLength = pagination.itemsLength;
      this.itemStart = pagination.pageStart;
      this.itemStop = pagination.pageStop;
      this.itemsPerPage = pagination.itemsPerPage;
      await this.loadList();
    },

    checkoutBox(event) {
      if (!event) {
        this.updateData.doNotContact = false;
      } else {
        this.updateData.doNotContact = event;
      }
    },
    changePurcha(event) {
      this.updateData.purchaseRateId = event;
      this.purchaseRateId = event;
    },
    changeFurther(event) {
      this.updateData.furtherPurchaseRateId = event;
      this.furtherPurchaseRateId = event;
    },
    changeRenew(event) {
      this.updateData.renewPurchaseRateId = event;
      this.renewPurchaseRateId = event;
    },

    changeNegot(event) {
      this.updateData.negotiationExitReason = event;
      this.negotiationExitReason = event;
    },
    changeLastRes(event) {
      this.updateData.lastResponseDate = event;
      this.lastResponseDate = event;
    },
    changeSeasons(event) {
      this.updateData.seasonsInterested = event;
      this.seasonsInterested = event;
    },
    changeFaci(e) {
      const formatted = this.formatFacilitiesInterested(e);
      this.updateData.facilitiesInterested = formatted;
      this.facilitiesInterested = formatted;
    },
    changeID(e) {
      this.updateData.picId = e;
      this.picId = e;
    },

    changeNegotiationExitReason(value) {
      var string = '';
      if (value.length === 1) {
        string += value[0];
      } else {
        for (let i = 0; i < value.length; i++) {
          if (i === value.length - 1) {
            string += value[i];
          } else {
            string += value[i] + ', ';
          }
        }
      }
      this.updateData.negotiationExitReason = string;
      this.negotiationExitReason = value;
    },

    actionUpdatePro() {
      this.setDataUpdatePro({ dataUpdate: this.updateData });
      this.getUpdateClient();
    },

    formatDateTimeZone(date) {
      return moment(date).format('MM/DD HH:mm');
    },
    getSelectTableFormat(array) {
      const arr = [];
      array.forEach(e => {
        arr.push(e.campaign);
      });
      return arr;
    },
    getFormatFac(obj) {
      let arr = '';
      if (obj) {
        arr = obj.name;
      }
      return arr;
    },

    // action vsinterest
    async getUnserInfoClientCurrent(idClient) {
      const variables = {
        id: idClient,
      };
      await this.$apollo
        .query({
          query: gql`
            ${CLIENT_BASIC_INFO_CRRENT}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          if (data.data.clientBasicInfo) {
            this.typeShow = data.data.clientBasicInfo.type;
            if (this.typeShow === TYPE_CLIENT.VSINTEREST) {
              this.getListBrochre(idClient);
            }
          }
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              CLIENT_BASIC_INFO_CRRENT,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    async getListBrochre(idClient) {
      this.setLoadingOverlayShow();
      const variables = {
        clientId: parseInt(idClient),
      };
      await this.$apollo
        .query({
          query: gql`
            ${WAITLIST_BROCHURE}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          this.setLoadingOverlayHide();
          if (
            data.data.webRequestList &&
            data.data.webRequestList.items.length > 0
          ) {
            this.setListBrochureVsinterest({
              listBrochureVsinterest: data.data.webRequestList.items,
            });
          }
        })
        .catch(async error => {
          this.setLoadingOverlayHide();
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              WAITLIST_BROCHURE,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    redirectNo43CRM() {
      this.visible = 2;
    },
    redirectNo41CRM() {
      const routeDataClient = this.$router.resolve({
        path: `/customer-details/potential-information/uncontracted-leads/${this.idClient}`,
      });
      window.open(routeDataClient.href, '_blank');
    },

    actionUpdate() {
      let listData = [];
      if (this.finishData.negotiationExitReason) {
        listData = this.finishData.negotiationExitReason.map(item => item);
      }
      const updateDataClient = {
        ...this.finishData,
        negotiationExitReason: listData.join(','),
        facilitiesInterested: this.formatFacilitiesInterested(
          this.finishData.facilitiesInterested,
        ),
      };
      // delete updateDataClient.type;
      // delete updateDataClient.pic;
      // delete updateDataClient.__typename;
      // // delete updateDataClient.negotiationExitReason.__typename;
      // // delete updateDataClient.negotiationExitReason;
      // // if (this.finishData.negotiationExitReason) {
      // //   updateDataClient.negotiationExitReason =
      // //     this.finishData.negotiationExitReason;
      // // }
      this.actionUpdateClient(updateDataClient);
    },

    formatFacilitiesInterested(data) {
      if (!data) return data;
      return data
        .map(item => {
          if (typeof item === 'string') return item;
          if (item.name) return item.name;
          return null;
        })
        .filter(v => !!v);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 20px !important;
  font-weight: 600;
  color: #0b6786;
}
.web-request-lists ::v-deep h3 {
  font-size: 18px !important;
  font-weight: 500;
  color: #0c6786 !important;
}
.w-100 {
  width: 100%;
}
.w-70 {
  width: 70%;
}
.w-50 {
  width: 50%;
}
.w-45 {
  width: 45%;
}
.w-20 {
  width: 20%;
}
.w-33 {
  width: calc(100% / 3);
}
.btnStatus {
  width: 70px;
  color: white;
  border-radius: 6px;
  text-align: right;
}
.web-request-lists::v-deep {
  th,
  .v-select__selection {
    font-size: 14px !important;
    color: #757575 !important;
    font-weight: bold;
    white-space: nowrap;
  }
  tbody {
    tr {
      td {
        color: #757575 !important;
        font-size: 16px !important;
        font-weight: 400;
        white-space: nowrap;
        .v-btn__content {
          span {
            color: #fff !important;
          }
        }
      }
    }
  }
  .v-data-footer {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000000 !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
.mdi-pencil-circle {
  background: #f5f5f5 !important;
}
::v-deep .table1 {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th:nth-child(1) {
            width: 40px !important;
          }
          td:last-child() {
            text-align: end !important;
          }
        }
        tr {
          th:nth-child(2) {
            width: 50px !important;
          }
        }
        tr {
          th:nth-child(3) {
            width: 130px !important;
          }
        }
        tr {
          th:nth-child(4) {
            width: 130px !important;
          }
        }
        tr {
          th:nth-child(5) {
            width: 75px !important;
          }
        }
        tr {
          th:nth-child(6) {
            width: 100px !important;
          }
        }
        tr {
          th:nth-child(7) {
            text-align: end !important;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(7) {
            text-align: end !important;
          }
        }
      }
    }
  }
}

::v-deep .table3,
::v-deep .table2 {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th:nth-child(1) {
            width: 65px !important;
          }
          td:last-child() {
            text-align: end !important;
          }
        }
        tr {
          th:nth-child(2) {
            width: 70px !important;
          }
        }
        tr {
          th:nth-child(3) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(4) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(5) {
            text-align: end !important;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(5) {
            text-align: end !important;
          }
        }
      }
    }
  }
}
::v-deep .table4 {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th:nth-child(1) {
            width: 65px !important;
          }
          td:last-child() {
            text-align: end !important;
          }
        }
        tr {
          th:nth-child(2) {
            width: 100px !important;
          }
        }
        tr {
          th:nth-child(3) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(4) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(5) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(6) {
            text-align: end !important;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(6) {
            text-align: end !important;
          }
        }
      }
    }
  }
}

::v-deep {
  .v-data-table__wrapper {
    max-height: 300px;
    overflow-y: scroll;
  }
  .Advertising {
    .v-select__selection--comma {
      color: #000000 !important;
      font-weight: 500;
      font-size: 10px !important;
    }
    .t-btn--red-dark {
      color: #fafafa !important;
    }
    .v-input--is-dirty {
      width: 200px !important;
    }
    .menuable__content__active {
      width: 200px !important;
    }
  }
  .v-custom-table.--scroll {
    max-height: 340px;
    overflow-y: scroll;
  }
  .v-list .v-sheet .theme--light {
    .px-2 .div-select-dialog {
      width: 200px !important;
    }
    .v-list-item__content,
    .v-select__selections {
      .v-select__slot {
        width: 137px !important;
      }
    }
  }
  // select and input
  .v-input__control {
    height: 40px;
    input {
      font-size: 12px;
    }
    .v-label {
      color: #757575 !important;
      &--active {
        color: #000000 !important;
      }
    }
  }
  .v-text-field__slot,
  .v-select__slot {
    .v-label {
      font-size: 10px !important;
      font-weight: 600;
      color: #000;
    }
  }
  .v-select__selection {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
  }

  .v-input__icon {
    svg {
      color: #333333;
    }
  }
  .v-input--checkbox {
    .v-label {
      font-size: 14px !important;
      color: #000000 !important;
      font-weight: 500;
    }
  }
  // .v-input--is-dirty {
  //   .v-label {
  //     font-size: 14px !important;
  //     font-weight: 500;
  //     color: #000000;
  //   }
  //   input {
  //     font-size: 14px !important;
  //     font-weight: 500;
  //     color: #000;
  //   }
  // }
  // button
  .btn-ad-custom {
    width: 35px;
    height: 35px;
    background-color: #13ace0;
  }
  .btn-status {
    width: 73px;
    height: 27px;
    font-size: 16px;
  }
  .btn-edit {
    border: 1px solid white;
  }

  .btn-download {
    background-color: #13ace0;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    color: white;
    font-size: 22px;
    padding: 5px 8px;
  }
  // title
  .text-16px {
    font-size: 16px;
  }

  .title-header {
    text-align: center;
    font-size: 20px;
    color: #000000;
  }
  .v-data-footer__pagination {
    display: none;
  }
}
.text-12px {
  word-break: keep-all !important;
}
.checkbox {
  width: fit-content;
}

.alert-negotiating-customer {
  margin-top: 2rem;
  width: 503px;
  height: 46px;
  background-color: #32b679;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  .alert-negotiating-customer-title {
    margin-left: 1rem;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
  }
  .alert-negotiating-customer-btn {
    margin-left: 6rem;
    width: 157px;
    height: 32px;
    font-size: 14px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background: unset;
  }
}
</style>
