<template>
  <div class="mx-2 mt-2 mb-4">
    <div class="d-flex justify-space-between pt-3">
      <h3 class="ml-5 mb-3">資料請求</h3>
      <v-btn
        v-if="!hideAdd"
        style="width: 120px; height: 24px; padding: 4px"
        class="AddedDocument btn-crm-primary"
        @click="clickAdd"
        :disabled="!checkPerUser"
      >
        <span class="text-10px mx-5">新規資料請求を追加</span>
      </v-btn>
    </div>

    <v-card>
      <v-simple-table class="table1" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="(item, i) in headers"
                :key="i"
              >
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="itemsWithCampainArray.length > 0">
              <tr
                v-for="(item, index) in itemsWithCampainArray"
                :key="index"
              >
                <td>
                  {{ item.createdAt | formatMonthDate }}
                </td>
                <td>{{ showBillingMaterials(item) }}</td>
                <td>{{ item.remarks }}</td>
                <td>{{ item.campaignNames }}</td>
                <td>
                  <v-btn
                    v-for="campaign in item.campaigns"
                    :key="campaign.id"
                    class="btn-ad-custom"
                    icon
                    @click="
                      (campaignDetailDialog = true),
                        (campaignId = campaign.id)
                    "
                  >
                    <ad :witdh="30" :height="30" />
                  </v-btn>
                </td>
                <td>{{ item.method }}</td>
                <td>
                  <v-btn
                    class="btn-crm-primary btn-edit"
                    :disabled="!checkPerUser"
                    icon
                    @click="handleEditDialog(item.id)"
                  >
                    <v-icon x-large>mdi-pencil-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="text-center text-heading" colspan="7">
                {{ $t('rules.noData') }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <!-- Dialog -->
    <AdvertisingPoliciesDetailDialog
      v-if="campaignDetailDialog"
      @close="campaignDetailDialog = false"
      :idPolicy="campaignId"
    />
    <document-request-detail
      v-if="checkPerUser && detailDialog"
      :id="requestId"
      @close="detailDialog = false"
    />
    <added-new-document-request
      :visible="addDialog"
      @close="addDialog = false"
    ></added-new-document-request>
  </div>
</template>

<script>
import AdvertisingPoliciesDetailDialog from '@/views/advertising/policies/advertisingPoliciesDetailDialog.vue';
import DocumentRequestDetail from '../../../../dashboard/webPageManage/documentrequest/DuplicateExistingCustomers/documentRequestDetail.vue';
import Ad from '@/components/icons/ad.vue';
import AddedNewDocumentRequest from './AddedNewDocumentRequest.vue';

export default {
  components: {
    AdvertisingPoliciesDetailDialog,
    DocumentRequestDetail,
    Ad,
    AddedNewDocumentRequest,
  },
  props: {
    checkPerUser: Boolean,
    items: Array,
    hideAdd: Boolean
  },
  data () {
    return {
      campaignDetailDialog: false,
      campaignId: null,
      detailDialog: false,
      requestId: null,
      addDialog: false
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '受信日',
          value: 'createdAt',
          width: '36px',
        },
        {
          text: '請求資料',
          value: 'BillingMaterials',
          width: '48px',
        },
        {
          text: '備考',
          value: 'remarks',
          width: '123px',
        },
        {
          text: '広告施策',
          value: 'campaignName',
          width: '123px',
        },
        {
          text: '広告施策詳細',
          value: 'AdvertisingDetails',
          align: 'center',
          width: '72px',
        },
        {
          text: '広告施策適応方法',
          value: 'campaignMethod',
          width: '96px',
        },
        {
          text: '詳細',
          value: 'edit',
          align: 'right',
        },
      ]
    },
    itemsWithCampainArray () {
      if (!this.items) return null

      return this.items.map(item => {
        const campaigns = item.medias
          .filter(media => media.campaign)
          .map(media => media.campaign)
        if (item.campaign) campaigns.push(item.campaign);
        const method = campaigns.length
          ? item.campaign
            ? '手動'
            : '自動'
          : 'なし';
        return {
          ...item,
          campaigns,
          method,
          campaignNames: campaigns.map(c => c.name).join(', '),
          campaignIds: campaigns.map(c => c.id)
        }
      })
    }
  },
  methods: {
    showBillingMaterials(item) {
      var arr = [];
      if (item.forVs) {
        arr.push('VS');
      }
      if (item.forVm) {
        arr.push('VM');
      }
      return arr.toString();
    },
    handleEditDialog (id) {
      this.requestId = id
      this.detailDialog = true
    },
    clickAdd () {
      this.addDialog = true
    }
  }
}
</script>