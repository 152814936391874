<template>
  <v-simple-table
    fixed-header
    class="table4"
    max-height="300px"
    dense
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th
            v-for="(item, i) in headerExperienceAccommodation"
            :key="i"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="items.length > 0">
          <tr
            v-for="(item, index) in items"
            :key="index"
          >
            <td>
              <v-chip
                :color="
                  item.handledAt === null
                    ? '#DB3069'
                    : '#616161'
                "
                class="btn-status"
                label
                text-color="white"
              >
                {{
                  item.handledAt === null
                    ? '未対応'
                    : '対応済'
                }}
              </v-chip>
            </td>

            <td>
              {{ item.createdAt | formatMonthDate }}
            </td>
            <td>{{ item.NumberOfTimes }}</td>
            <td>
              {{ item.firstCheckInDate | formatMonthDate }}
              {{ item.firstStayDays | stayDays }}
              {{ getFaclityName(item.firstFacility) }}
            </td>
            <td>
              {{ item.secondCheckInDate | formatMonthDate }}
              {{ item.secondStayDays | stayDays }}
              {{ getFaclityName(item.secondFacility) }}
            </td>
            <td>
              <v-btn
                class="btn-crm-primary btn-edit"
                icon
                @click="$emit('detailClick', item)"
              >
                <v-icon x-large>mdi-pencil-circle</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td class="text-center text-heading" colspan="6">
            {{ $t('rules.noData') }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      headerExperienceAccommodation: [
        {
          text: 'ステータス',
          value: 'handledAt',
          width: '100px',
        },
        {
          text: '受信日',
          value: 'createdAt',
          width: '100px',
        },
        {
          text: '回数',
          value: 'NumberOfTimes',
          width: '150px',
        },
        {
          text: '第一希望日',
          value: 'FirstChoiceDate',
          width: '150px',
        },
        {
          text: '第二希望日',
          value: 'SecondChoiceDate',
          width: '150px',
        },
        {
          text: '詳細',
          value: 'edit',
          align: 'right',
        },
      ],
    }
  },
  methods: {
    getWebReqEditFacility (payload) {
      return this.$store.dispatch('getWebReqEditFacility', payload)
    },
    getFaclityName (facility) {
      return facility?.name || ''
    }
  }
}
</script>
